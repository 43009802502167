import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';

interface Props {
  data: any;
}

const UserManual: React.FC<Props> = ({ data }) => {
  React.useEffect(() => {
    if (data.allFile.edges) {
      const userManual = data.allFile.edges.find((e: any) =>
        e.node.publicURL.includes('user-manual'),
      );
      if (userManual) {
        window.location.replace(userManual.node.publicURL);
      }
    }
  });
  return <div />;
};

export default () => (
  <StaticQuery
    query={graphql`
      query FetchPdfQuery {
        allFile(filter: { extension: { eq: "pdf" } }) {
          edges {
            node {
              publicURL
            }
          }
        }
      }
    `}
    render={data => <UserManual data={data} />}
  />
);
